var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: {
        flat:
          _vm.references.cardFlat !== undefined
            ? _vm.references.cardFlat
            : false,
      },
    },
    [
      _c(
        "v-form",
        { ref: "validateForm", attrs: { autocomplete: "off" } },
        [
          _vm.references.title
            ? _c(
                "v-card-title",
                {
                  class:
                    _vm.references.cardTitleClass !== undefined
                      ? _vm.references.cardTitleClass
                      : "px-2 py-1",
                  attrs: { "primary-title": "" },
                },
                [
                  _c("h5", [_vm._v(" " + _vm._s(_vm.references.title) + " ")]),
                  _c("v-spacer"),
                ],
                1
              )
            : _vm._e(),
          _vm.references.title ? _c("v-divider") : _vm._e(),
          _c(
            "v-container",
            { attrs: { fluid: "", "grid-list-xl": "" } },
            [
              _c(
                "v-layout",
                {
                  class:
                    _vm.references.layoutClass !== undefined
                      ? _vm.references.layoutClass
                      : "px-0 py-1",
                  attrs: { wrap: "" },
                },
                [
                  _vm._l(_vm.references.properties, function (prop, index) {
                    return [
                      (prop.is_show !== undefined ? prop.is_show : true)
                        ? _c(
                            "v-flex",
                            {
                              key: index,
                              class: prop.class,
                              staticStyle: { padding: "5px !important" },
                              style: prop.style,
                            },
                            [
                              prop.type === _vm.formType.TEXT
                                ? [
                                    _c("v-text-field", {
                                      ref: prop.ref,
                                      refInFor: true,
                                      attrs: {
                                        outlined: "",
                                        maxlength: prop.max ? prop.max : "",
                                        "hide-details": "auto",
                                        label: prop.label,
                                        suffix: prop.suffix,
                                        id: prop.model,
                                        disabled: prop.disabled,
                                        rules: prop.rules,
                                        dense: "",
                                        "append-icon":
                                          prop.appendIcon !== undefined
                                            ? prop.appendIcon
                                            : "",
                                        readonly: prop.readonly,
                                      },
                                      on: {
                                        input: prop.input,
                                        change: function ($event) {
                                          prop.change ? prop.change() : ""
                                        },
                                        "click:append": function ($event) {
                                          prop.appendClick !== undefined
                                            ? prop.appendClick()
                                            : null
                                        },
                                      },
                                      model: {
                                        value: _vm.model[prop.model],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.model, prop.model, $$v)
                                        },
                                        expression: "model[prop.model]",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              prop.type === _vm.formType.NUMBER
                                ? [
                                    _c("v-text-field", {
                                      attrs: {
                                        outlined: "",
                                        "hide-details": "auto",
                                        type: _vm.formType.NUMBER,
                                        id: prop.model,
                                        label: prop.label,
                                        rules: prop.rules,
                                        disabled: prop.disabled,
                                        dense: "",
                                      },
                                      model: {
                                        value: _vm.model[prop.model],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.model, prop.model, $$v)
                                        },
                                        expression: "model[prop.model]",
                                      },
                                    }),
                                  ]
                                : prop.type === _vm.formType.TEXTAREA
                                ? [
                                    _c("v-textarea", {
                                      attrs: {
                                        outlined: "",
                                        "hide-details": "auto",
                                        label: prop.label,
                                        id: prop.model,
                                        rules: prop.rules,
                                        disabled: prop.disabled,
                                        readonly:
                                          prop.readonly !== undefined
                                            ? prop.readonly
                                            : false,
                                        rows: prop.rows,
                                        dense: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          prop.change ? prop.change() : ""
                                        },
                                      },
                                      model: {
                                        value: _vm.model[prop.model],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.model, prop.model, $$v)
                                        },
                                        expression: "model[prop.model]",
                                      },
                                    }),
                                    prop.showHint
                                      ? _c(
                                          "label",
                                          {
                                            staticClass: "v-label theme--light",
                                            attrs: { for: prop.model },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-information-outline"),
                                            ]),
                                            _vm._v(" " + _vm._s(prop.hintMsg)),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                : prop.type === _vm.formType.CHECKBOX
                                ? [
                                    _c("v-checkbox", {
                                      attrs: {
                                        "hide-details": "auto",
                                        label: prop.label,
                                        id: prop.model,
                                        color: "primary",
                                        rules: prop.rules,
                                        disabled: prop.disabled,
                                      },
                                      on: {
                                        change: function ($event) {
                                          prop.click
                                            ? prop.click(prop.model)
                                            : ""
                                        },
                                      },
                                      model: {
                                        value: _vm.model[prop.model],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.model, prop.model, $$v)
                                        },
                                        expression: "model[prop.model]",
                                      },
                                    }),
                                  ]
                                : prop.type === _vm.formType.AUTO_COMPLETE
                                ? [
                                    _c("v-autocomplete", {
                                      ref: prop.term ? "" + prop.term : "",
                                      refInFor: true,
                                      attrs: {
                                        outlined: "",
                                        "hide-details": "auto",
                                        items: prop.items,
                                        id: prop.model,
                                        disabled: prop.disabled,
                                        dense: "",
                                        rules: prop.rules,
                                        "item-text": prop.select_text,
                                        multiple: prop.multiple,
                                        autocomplete: "off",
                                        "item-value": prop.select_value,
                                        label: prop.label,
                                        "append-icon": prop.is_list
                                          ? "add_circle"
                                          : "",
                                        clearable: "",
                                      },
                                      on: {
                                        "click:append": function ($event) {
                                          return prop.click(prop.term)
                                        },
                                        change: function ($event) {
                                          prop.change ? prop.change() : ""
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          prop.multiple
                                            ? {
                                                key: "selection",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  var index = ref.index
                                                  return [
                                                    index === 0
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item[
                                                                prop.select_text
                                                              ]
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    index === 1
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "grey--text caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "(+" +
                                                                _vm._s(
                                                                  _vm.model[
                                                                    prop.model
                                                                  ].length - 1
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "message.common.others"
                                                                  )
                                                                ) +
                                                                ")"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              }
                                            : null,
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.model[prop.model],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.model, prop.model, $$v)
                                        },
                                        expression: "model[prop.model]",
                                      },
                                    }),
                                    prop.helpTextShow && prop.helpText
                                      ? _c("span", [
                                          _vm._v(_vm._s(prop.helpText)),
                                        ])
                                      : _vm._e(),
                                  ]
                                : prop.type === _vm.formType.SELECT
                                ? [
                                    _c("v-select", {
                                      attrs: {
                                        outlined: "",
                                        "hide-details": "auto",
                                        items: prop.items,
                                        id: prop.model,
                                        rules: prop.rules,
                                        "item-text": prop.select_text,
                                        dense: "",
                                        multiple: prop.multiple,
                                        "item-value": prop.select_value,
                                        label: prop.label,
                                        disabled: prop.disabled,
                                      },
                                      on: {
                                        change: function ($event) {
                                          prop.change ? prop.change() : ""
                                        },
                                        click: function ($event) {
                                          prop.click ? prop.click() : ""
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          prop.select_all
                                            ? {
                                                key: "prepend-item",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "v-list-item",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleSelectAll(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.selectAllIcon(
                                                                        index
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "message.projects.all"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-divider", {
                                                      staticClass: "mt-2",
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              }
                                            : null,
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.model[prop.model],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.model, prop.model, $$v)
                                        },
                                        expression: "model[prop.model]",
                                      },
                                    }),
                                  ]
                                : prop.type === _vm.formType.PASSWORD
                                ? [
                                    _c("v-text-field", {
                                      attrs: {
                                        outlined: "",
                                        "hide-details": "auto",
                                        label: prop.label,
                                        id: prop.model,
                                        rules: prop.rules,
                                        type: _vm.formType.PASSWORD,
                                        dense: "",
                                      },
                                      model: {
                                        value: _vm.model[prop.model],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.model, prop.model, $$v)
                                        },
                                        expression: "model[prop.model]",
                                      },
                                    }),
                                  ]
                                : prop.type === _vm.formType.COMBOBOX
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "combo" },
                                      [
                                        _c("v-combobox", {
                                          attrs: {
                                            items: prop.items,
                                            "hide-selected": "",
                                            rules: prop.rules,
                                            label: prop.label,
                                            multiple: prop.multiple,
                                            "persistent-hint": "",
                                            "small-chips": "",
                                            id: prop.model,
                                            disabled: prop.disabled,
                                            outlined: "",
                                            dense: "",
                                            "hide-details": "auto",
                                          },
                                          on: {
                                            change: function ($event) {
                                              prop.change ? prop.change() : ""
                                            },
                                          },
                                          model: {
                                            value: _vm.model[prop.model],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                prop.model,
                                                $$v
                                              )
                                            },
                                            expression: "model[prop.model]",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : prop.type === _vm.formType.DATEPICKER
                                ? [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          "close-on-content-click": false,
                                          transition: "scale-transition",
                                          "offset-y": "",
                                          "min-width": "290px",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          outlined: "",
                                                          "hide-details":
                                                            "auto",
                                                          rules: prop.rules,
                                                          clearable: "",
                                                          label: prop.label,
                                                          "prepend-inner-icon":
                                                            "mdi-calendar",
                                                          dense: "",
                                                          readonly: "",
                                                          disabled:
                                                            prop.disabled,
                                                        },
                                                        on: {
                                                          "click:clear":
                                                            function ($event) {
                                                              _vm.$nextTick(
                                                                function () {
                                                                  return (_vm.model[
                                                                    prop.picker
                                                                  ] = null)
                                                                }
                                                              )
                                                            },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.model[
                                                              prop.model
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.model,
                                                              prop.model,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "model[prop.model]",
                                                        },
                                                      },
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.model[prop.menu],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.model, prop.menu, $$v)
                                          },
                                          expression: "model[prop.menu]",
                                        },
                                      },
                                      [
                                        _c("v-date-picker", {
                                          attrs: {
                                            color: prop.color,
                                            min: _vm.model[prop.min],
                                            max: _vm.model[prop.max],
                                            "first-day-of-week": "1",
                                            disabled: prop.disabled,
                                            "weekday-format": _vm.getDayFormat,
                                          },
                                          on: {
                                            input: function ($event) {
                                              _vm.model[prop.menu] = false
                                              _vm.model[prop.model] =
                                                _vm.$formatter.formatDate(
                                                  _vm.model[prop.picker],
                                                  "YYYY-MM-DD",
                                                  "DD.MM.YYYY"
                                                )
                                            },
                                          },
                                          model: {
                                            value: _vm.model[prop.picker],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                prop.picker,
                                                $$v
                                              )
                                            },
                                            expression: "model[prop.picker]",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : prop.type === _vm.formType.DATETIMEPICKER
                                ? [_vm._t(prop.slot)]
                                : prop.type === _vm.formType.QUICKADD
                                ? [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          fab: "",
                                          small: "",
                                          outline: "",
                                          color: "primary",
                                          id: prop.type,
                                          disabled: prop.disabled,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push(prop.to)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [_vm._v("perm_identity")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : prop.type === _vm.formType.TIMEPICKER
                                ? [
                                    _c(
                                      "v-menu",
                                      {
                                        ref: prop.menu,
                                        refInFor: true,
                                        attrs: {
                                          "close-on-content-click": false,
                                          transition: "scale-transition",
                                          "offset-y": "",
                                          "min-width": "290px",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          slot: "activator",
                                                          outlined: "",
                                                          "hide-details":
                                                            "auto",
                                                          label: prop.label,
                                                          rules: prop.rules,
                                                          dense: "",
                                                          readonly: "",
                                                          "prepend-inner-icon":
                                                            "mdi-clock-outline",
                                                          clearable: "",
                                                          disabled:
                                                            prop.disabled,
                                                        },
                                                        slot: "activator",
                                                        model: {
                                                          value:
                                                            _vm.model[
                                                              prop.model
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.model,
                                                              prop.model,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "model[prop.model]",
                                                        },
                                                      },
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.model[prop.menu],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.model, prop.menu, $$v)
                                          },
                                          expression: "model[prop.menu]",
                                        },
                                      },
                                      [
                                        _c("v-time-picker", {
                                          attrs: {
                                            color: "primary",
                                            min: _vm.setMin(
                                              _vm.model[prop.min]
                                            ),
                                            max: _vm.model[prop.max],
                                            "allowed-minutes": _vm.allowedStep,
                                            format: "24hr",
                                            disabled: prop.disabled,
                                          },
                                          on: {
                                            "click:minute": function ($event) {
                                              _vm.$refs[prop.menu][0].save(
                                                _vm.model[prop.model]
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.model[prop.model],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                prop.model,
                                                $$v
                                              )
                                            },
                                            expression: "model[prop.model]",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : prop.type === _vm.formType.FILES
                                ? [
                                    _vm.isIos
                                      ? _c("input", {
                                          attrs: {
                                            type: "file",
                                            id: prop.id ? prop.id : "",
                                            "prepend-inner-icon":
                                              "mdi-paperclip",
                                            "prepend-icon": "",
                                            accept: prop.accept,
                                            "hide-details": "auto",
                                            disabled: prop.disabled,
                                            outlined: "",
                                            rules: prop.rules,
                                            label: prop.label,
                                            multiple: prop.multiple,
                                            dense: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleFileUpload(
                                                $event,
                                                prop
                                              )
                                            },
                                          },
                                        })
                                      : _c("v-file-input", {
                                          attrs: {
                                            "prepend-inner-icon":
                                              "mdi-paperclip",
                                            "prepend-icon": "",
                                            accept: prop.accept,
                                            "hide-details": "auto",
                                            disabled: prop.disabled,
                                            outlined: "",
                                            rules: prop.rules,
                                            label: prop.label,
                                            multiple: prop.multiple,
                                            dense: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              prop.change ? prop.change() : ""
                                            },
                                          },
                                          model: {
                                            value: _vm.model[prop.model],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                prop.model,
                                                $$v
                                              )
                                            },
                                            expression: "model[prop.model]",
                                          },
                                        }),
                                    _vm._t(prop.slot),
                                  ]
                                : prop.type === "warehouse_product_batchno"
                                ? [_vm._t(prop.slot)]
                                : prop.type === "weld_end_complete"
                                ? [_vm._t(prop.slot)]
                                : prop.type === _vm.formType.COLORPICKER
                                ? [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          bottom: "",
                                          "close-on-content-click": false,
                                          "offset-y": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "ma-0 pa-0",
                                                        attrs: {
                                                          slot: "activator",
                                                          label: prop.label,
                                                          outlined: "",
                                                          "hide-details":
                                                            "auto",
                                                          readonly: "",
                                                          disabled:
                                                            prop.disabled,
                                                          dense: "",
                                                        },
                                                        slot: "activator",
                                                        model: {
                                                          value:
                                                            _vm.model[
                                                              prop.model
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.model,
                                                              prop.model,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "model[prop.model]",
                                                        },
                                                      },
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.model[prop.menu],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.model, prop.menu, $$v)
                                          },
                                          expression: "model[prop.menu]",
                                        },
                                      },
                                      [
                                        [prop.menu]
                                          ? _c("v-color-picker", {
                                              attrs: {
                                                bottom: "",
                                                flat: "",
                                                disabled: prop.disabled,
                                              },
                                              model: {
                                                value: _vm.model[prop.model],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.model,
                                                    prop.model,
                                                    $$v
                                                  )
                                                },
                                                expression: "model[prop.model]",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                : prop.type === _vm.formType.RADIO &&
                                  prop.is_show
                                ? [
                                    _c(
                                      "v-radio-group",
                                      {
                                        attrs: { row: "" },
                                        on: {
                                          change: function ($event) {
                                            prop.click
                                              ? prop.click(prop.model)
                                              : ""
                                          },
                                        },
                                        model: {
                                          value: _vm.model[prop.model],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.model, prop.model, $$v)
                                          },
                                          expression: "model[prop.model]",
                                        },
                                      },
                                      [
                                        _vm._l(prop.items, function (items) {
                                          return [
                                            _c("v-radio", {
                                              key: items.value,
                                              attrs: {
                                                label: items.text,
                                                value: items.value,
                                              },
                                            }),
                                          ]
                                        }),
                                      ],
                                      2
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [_vm._t("extraDetails")],
            2
          ),
          _vm.references.buttons.length > 0 ? _c("v-divider") : _vm._e(),
          _vm.references.buttons
            ? _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _vm._l(
                            _vm.references.buttons,
                            function (button, index) {
                              return [
                                button.is_show
                                  ? _c(
                                      "v-btn",
                                      {
                                        key: index,
                                        staticClass: "mr-2",
                                        attrs: {
                                          loading: button.loading,
                                          id: button.name,
                                          color: button.color,
                                          disabled: button.disabled,
                                        },
                                        on: { click: button.click },
                                      },
                                      [_vm._v(" " + _vm._s(button.label) + " ")]
                                    )
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._t("button"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }